import React, { useState } from "react";
import "./becomeAnMember.scss";
import { Button, Form, Input, Select } from "antd";
import Icon from "@ant-design/icons";
import { rightArrow } from "../../constants/icons";
import { useMutation } from "@apollo/client";
import SocialIcons from "../../components/Social/SocialIcons";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'libphonenumber-js';
// static_data
import { items, industry } from "../Contact/static_data";
// api
import { joinMember } from "../../api";
// components
import MailSucessComponent from "../../components/Sucess/mailSucess";
//loader
import Loader from "../../components/Loader/loader";

const BecomeAnMember = () => {
  const [addMember, { data, loading }] = useMutation(joinMember);
  const [isValueSelected, setIsValueSelected] = useState(null);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [isSelectedIndustry, setIsSelectedIndustry] = useState(null);
  const [file, setFile] = useState('');
  const [phone, setPhone] = useState('');
  const [base64, setBase64] = useState(null);

  const { Option } = Select;
  console.log('form', file)

  const onFinish = async (values) => {
    
    if (base64) {
      values.companyLogo = base64;
    }

    await addMember({ variables: values });
  };

  const handleSelect = (value) => {
    setIsValueSelected(value);
  };

  const handleSelectIndustry = (value) => {
    setIsSelectedIndustry(value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files?.[0];
    setFile(selectedFile || null);

    const reader = new FileReader();
      reader.onload = () => {
        setBase64(reader.result)
      }
      reader.onerror = (error) => {
        console.error("File could not be read:", error)
      };
      reader.readAsDataURL(selectedFile);
  }

  const handleFileDelete = () => {
    setFile('')
  };

  const validateFile = (file) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    console.log('allowedTypes.includes(file.type)', allowedTypes.includes(file.type))
    return allowedTypes.includes(file.type);

  };

  let displayCont = data ? (
    <MailSucessComponent />
  ) : (
    <div className="become__a__member__section__block">
      <div className="become__a__member__section__block--top">
        <p className="become__a__member__section__block--top-member-tier">
          <span className="color-black"> Join now to </span>
          <span className="color-yellow">spark connections!</span>
        </p>
      </div>
      <div className="become__a__member__section__block--bottom">
          <div className="contact__section--right-form become__a__member--form">
            <Form
              form={form}
              className="become__a__member__section__block-contact-form"
              name="become__a__member"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              < div className="contact__section--right-form-inputs become__a__member">

                <Form.Item
                  labelClassName="custom-label-class"
                  label="First Name"
                  name="firstName"
                  className="contact-form-input"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your First name!",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z ]+$/),
                      message: "No Numbers or Special Characters Allowed",
                    },
                    {
                      pattern: new RegExp(/^[\s\S]{1,50}$/),
                      message: "Maximum 50 characters allowed!",
                    }
                  ]}
                >
                  <Input className="contact-input" />
                </Form.Item>
                <Form.Item
                  labelClassName="custom-label-class"
                  label="Last Name"
                  name="lastName"
                  className="contact-form-input"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Last name!",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z ]+$/),
                      message: "No Numbers or Special Characters Allowed",
                    },
                    {
                      pattern: new RegExp(/^[\s\S]{1,50}$/),
                      message: "Maximum 50 characters allowed!",
                    }
                  ]}
                >
                  <Input className="contact-input" />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  // labelClassName="custom-label-class"
                  name="phone"
                  // label="Phone Number"
                  className="contact-form-input"
                  rules={[
                    { required: true, message: 'Please enter your phone number!' },
                    () => ({
                      validator(_, value) {
                        if (value && !isValidPhoneNumber(value)) {
                          return Promise.reject('Please enter a valid phone number');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <PhoneInput
                    international
                    defaultCountry="US"
                    value={phone}
                    onChange={value => setPhone(value)}
                    placeholder="Enter phone number"
                    className="contact-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  className="contact-form-input"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                    {
                      pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                      message: "Enter a valid email address",
                    },
                  ]}
                >
                  <Input className="contact-input" />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  label="Address"
                  name="address"
                  className="contact-form-input query"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Address",
                    },
                    {
                      pattern: new RegExp(/^[\s\S]{1,250}$/),
                      message: "Maximum 250 characters allowed!",
                    }
                  ]}
                >
                  <TextArea rows={1} className="contact-input" />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  label="Company Name"
                  name="company"
                  className="contact-form-input"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Company Name!",
                    },
                    {
                      pattern: new RegExp(/^[\s\S]{1,100}$/),
                      message: "Maximum 100 characters allowed!",
                    }
                  ]}
                >
                  <Input className="contact-input" />
                </Form.Item>
                <Form.Item
                  labelClassName="custom-label-class"
                  label="Company Logo"
                  name="companyLogo"
                  className="contact-form-input"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Company Logo!",
                    },
                    {
                      // Custom validator for the file input to check its type
                      validator: async (_, value) => {
                        if (value && value[0]) {
                          const file = value[0];

                          console.log('file', file)
                          if (!validateFile(file)) {
                            return Promise.reject(new Error("Invalid file type. Only .png, .jpg, and .jpeg are allowed."));
                          }
                        }
                        return Promise.resolve(); // Everything is fine, no error
                      },
                    },
                  ]}
                >
                    <Input
                      className="contact-input choose-button"
                      type="file"
                      onChange={handleFileChange}
                      accept=".png, .jpg, .jpeg"
                    />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  className="contact-form-input"
                  name="designation"
                  label={
                    !isValueSelected || isValueSelected === "Others"
                      ? "Designation"
                      : null
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter your designation!",
                    },
                  ]}
                >
                  {isValueSelected === "Others" ? (
                    <Input className="contact-input" />
                  ) : (
                    <Select onChange={handleSelect} options={items} />
                  )}
                </Form.Item>
                <Form.Item
                  label="Sub-Designation"
                  name="subDesignation"
                  className="contact-form-input"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Sub-Designation!",
                    },
                  ]}
                >
                  <Input className="contact-input" />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  className="contact-form-input"
                  name="industry"
                  label={
                    !isSelectedIndustry || isSelectedIndustry === "Others"
                      ? "Industry"
                      : null
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter Industry!",
                    },
                  ]}
                >
                  {isSelectedIndustry === "Others" ? (
                    <Input className="contact-input" />
                  ) : (
                    <Select onChange={handleSelectIndustry} options={industry} />
                  )}
                </Form.Item>
                <Form.Item
                  label="Sub-Category"
                  name="subCategory"
                  className="contact-form-input"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Sub-Category!",
                    },
                    {
                      pattern: new RegExp(/^[\s\S]{1,100}$/),
                      message: "Maximum 100 characters allowed!",
                    }
                  ]}
                >
                  <Input className="contact-input" />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  labelClassName="custom-label-class"
                  label="Website/Domain"
                  name="website"
                  className="contact-form-input"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL!",
                    },
                    {
                      required: false,
                      message: "URL is required!",
                    },
                  ]}
                >
                  <Input className="contact-input" type="url" />
                </Form.Item>
                <Form.Item
                  labelClassName="custom-label-class"
                  label="GST Number"
                  name="gstNumber"
                  className="contact-form-input"
                  rules={[
                    {
                      required: false,
                      message: "Please enter a valid GST Number!",
                    },
                    {
                      pattern: new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i),
                      message: "Please enter a valid GST Number!",
                    },
                  ]}
                >
                  <Input className="contact-input" type="text" />
                </Form.Item>
              </div>
              <div className="contact__section--right-form-inputs become__a__member">
                <Form.Item
                  labelClassName="custom-label-class"
                  label="Employee count"
                  name="employeeCount"
                  className="contact-form-input"
                  rules={[
                    {
                      required: false,
                      message: "Please enter the number of employees!",
                    },
                    {
                      pattern: /^[1-9][0-9]{0,6}$/,
                      message: "Employee count should be a positive number and not exceed 7 digits!",
                    },
                  ]}
                >
                  <Input className="contact-input" type="number" />
                </Form.Item>
                <Form.Item
                  label="Remarks"
                  name="notesRemarks"
                  className="contact-form-input"
                  rules={[
                    {
                      required: false,
                      message: "Please enter your Remarks",
                    },
                    {
                      pattern: new RegExp(/^[\s\S]{1,500}$/),
                      message: "Maximum 250 characters allowed!",
                    }
                  ]}
                >
                  <TextArea rows={1} className="contact-input" />
                </Form.Item>
              </div>
              <Form.Item className="form-button-submit">
                <Button
                  className="contact-form-submit-btn become__a__member"
                  htmlType="submit"
                >
                  Send Message <Icon component={rightArrow} />
                </Button>
              </Form.Item>
            </Form>
          </div>
      </div>
    </div>
  );

  if (loading) {
    displayCont = <Loader />;
  }
  return (
    <section className="become__a__member__section scrollview">
      <SocialIcons />
      {displayCont}
    </section>
  );
};

export default BecomeAnMember;
