import React, { useState, useEffect, Fragment } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  gql,
  useLazyQuery,
} from "@apollo/client";
import {
  Table,
  Card,
  Input,
  Button,
  Tag,
  Dropdown,
  Checkbox,
  Menu,
  Row,
  Col,
} from "antd";
import { SearchOutlined, SettingOutlined } from "@ant-design/icons";
import Header from "../../components/Header/Header";
import "./AdminTable.scss";
import Loader from "../../components/Loader/loader";
import { useNavigate } from "react-router-dom";
import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GET_MEMBER_DETAILS } from "./queries";

// Create HTTP Link
const httpLink = createHttpLink({
  uri: "https://backend.aatralindia.org/graphql",
});

// Middleware to dynamically add token to headers
const authLink = setContext(() => {
  const token = localStorage.getItem("authToken") || "";
  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
});

// Apollo Client Setup
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const AdminTable = () => {
  const [members, setMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const [fetchMembers, { loading }] = useLazyQuery(GET_MEMBER_DETAILS, {
    variables: {
      search: searchQuery,
      page: pagination.current,
      pageSize: pagination.pageSize,
    },
    onCompleted: (data) => {
      const formattedData = data.joinMembers.data.map((member) => ({
        key: member.id,
        email: member.attributes.email,
        company: member.attributes.company,
        firstName: member.attributes.firstName,
        lastName: member.attributes.lastName,
        phone: member.attributes.phone,
        createdAt: new Date(member.attributes.createdAt).toLocaleString(),
        address: member.attributes.address,
        status: member.attributes.confirmationStatus,
        designation: member.attributes.designation,
        subDesignation: member.attributes.subDesignation,
        subCategory: member.attributes.subCategory,
        website: member.attributes.website,
        gstNumber: member.attributes.gstNumber,
        employeeCount: member.attributes.employeeCount,
        remarks: member.attributes.remarks,
        industry: member.attributes.industry,
        companyLogo: member.attributes.companyLogo,
      }));
      setMembers(formattedData);

      setPagination((prev) => ({
        ...prev,
        total: data.joinMembers.meta.pagination.total,
      }));
    },
  });

  console.log("isisisload", loading);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      fetchMembers(pagination.current, pagination.pageSize);
    } else {
      navigate("/admin-login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMembers, navigate]);

  const renderStatus = (status) => {
    let color;
    let text;

    switch (status) {
      case "Confirmed":
        color = "green";
        text = "Confirmed";
        break;
      case "Pending":
        color = "orange";
        text = "Pending";
        break;
      default:
        color = "blue";
        text = "Unknown";
        break;
    }

    return <Tag color={color}>{text}</Tag>;
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search First Name`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.firstName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Last Name`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.lastName.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Email`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.email.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => a.company.localeCompare(b.company),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Company`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.company.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search Phone`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.phone.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Address"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()} // Apply filter on pressing Enter
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.address.toLowerCase().includes(value.toLowerCase()), // Filter logic
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => renderStatus(status),
      filters: [
        { text: "Confirmed", value: "Confirmed" },
        { text: "Pending", value: "Pending" },
      ],
      onFilter: (value, record) =>
        record.status.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation), // Sorting logic
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Designation"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update selected keys on input change
            onPressEnter={() => confirm()} // Apply filter on pressing Enter
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.designation.toLowerCase().includes(value.toLowerCase()), // Filter logic
    },
    {
      title: "Sub-Designation",
      dataIndex: "subDesignation",
      key: "subDesignation",
      sorter: (a, b) => a.subDesignation.localeCompare(b.subDesignation), // Sorting logic
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Sub-Designation"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.subDesignation.toLowerCase().includes(value.toLowerCase()), // Filter logic
    },
    {
      title: "Sub-Category",
      dataIndex: "subCategory",
      key: "subCategory",
      sorter: (a, b) => a.subCategory.localeCompare(b.subCategory), // Sorting logic
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Sub-Category"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.subCategory.toLowerCase().includes(value.toLowerCase()), // Filter logic
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      render: (website) => (
        <a href={website} target="_blank" rel="noopener noreferrer">
          {website}
        </a>
      ),
      sorter: (a, b) => a.website.localeCompare(b.website), // Sorting logic for the website
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Website"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.website.toLowerCase().includes(value.toLowerCase()), // Filter logic
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
      sorter: (a, b) => a.gstNumber.localeCompare(b.gstNumber), // Sorting logic for GST Number
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search GST Number"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.gstNumber.toLowerCase().includes(value.toLowerCase()), // Filter logic
    },
    {
      title: "Employee Count",
      dataIndex: "employeeCount",
      key: "employeeCount",
      sorter: (a, b) => a.employeeCount - b.employeeCount, // Sorting logic for Employee Count
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            type="number" // Ensuring the input is numeric
            placeholder="Search Employee Count"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.employeeCount.toString().includes(value), // Filter logic based on the input
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      sorter: (a, b) => a.remarks.localeCompare(b.remarks), // Sorting logic for Remarks (alphabetical order)
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Remarks"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.remarks.toLowerCase().includes(value.toLowerCase()), // Filter logic based on Remarks
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
      sorter: (a, b) => a.industry.localeCompare(b.industry), // Sorting logic for Industry (alphabetical order)
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Industry"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } // Update filter value
            onPressEnter={() => confirm()} // Apply filter on Enter key press
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()} // Clear filter
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()} // Apply filter
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) =>
        record.industry.toLowerCase().includes(value.toLowerCase()), // Filter logic based on Industry
    },
    {
      title: "Company Logo",
      dataIndex: "companyLogo",
      key: "companyLogo",
      render: (logo, record) => {
        const fileName = `${record.company}-logo`;

        const fileUrl = logo;

        return (
          <a
            href={fileUrl}
            download={fileName}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt), // Sorting based on the date
      render: (date) => new Date(date).toLocaleString(), // Formatting the date to a readable string

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            type="date"
            placeholder="Search Date"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            } 
            onPressEnter={() => confirm()}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              size="small"
              onClick={() => clearFilters && clearFilters()}
            >
              Clear
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
        </div>
      ),
      onFilter: (value, record) => {
        // Compare dates after converting the value and the record's createdAt to a Date object
        const filterDate = new Date(value);
        const recordDate = new Date(record.createdAt);
        return (
          recordDate.setHours(0, 0, 0, 0) === filterDate.setHours(0, 0, 0, 0)
        ); // Compare only the date part (ignoring time)
      },
    },
  ];

  const defaultVisibleColumns = [
    "firstName",
    "lastName",
    "email",
    "company",
    "phone",
    "status",
    "designation",
    "industry",
  ];

  const [visibleColumns, setVisibleColumns] = useState(defaultVisibleColumns);
  const filteredColumns = columns.filter((column) =>
    visibleColumns.includes(column.key)
  );

  const handleColumnVisibilityChange = (checkedValues) => {
    setVisibleColumns(checkedValues);
  };

  const columnMenu = (
    <Menu style={{ maxHeight: "200px", overflowY: "auto", width: "200px" }}>
      <Menu.Item>
        <Checkbox.Group
          value={visibleColumns}
          onChange={handleColumnVisibilityChange}
        >
          <Row>
            {columns.map((column) => (
              <Col span={24} key={column.key}>
                <Checkbox value={column.key}>{column.title}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Menu.Item>
    </Menu>
  );

  const handleSearch = () => {
    setSearchQuery(searchInput);
    fetchMembers({ variables: { searchQuery } });
  };

  const fetchTableData = (page, pageSize) => {
    fetchMembers({
      variables: {
        page,
        pageSize,
      },
    });
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;

    setPagination({ ...pagination });

    fetchTableData(current, pageSize);
  };

  return (
    <Fragment>
      <Header />
      <div className="card-container">
        <Card className="card-content" bodyStyle={{ padding: "8px" }}>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              justifyContent: "space-between",
            }}
          >
            <Input
              placeholder="Search members."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              style={{ width: 300 }}
              disabled={loading}
              suffix={
                <Button
                  icon={<SearchOutlined />}
                  onClick={handleSearch}
                  style={{ border: "none", background: "transparent" }}
                  type="text"
                  disabled={loading}
                />
              }
            />

            <Dropdown
              overlay={columnMenu}
              trigger={["click"]}
              style={{ marginLeft: "10px", alignItems: "end" }}
              disabled={loading}
            >
              <Button
                type="primary"
                icon={<SettingOutlined />}
                style={{ marginLeft: "10px", background: "#fcb01a" }}
                disabled={loading}
              />
            </Dropdown>
          </div>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <Table
                columns={filteredColumns}
                dataSource={members}
                bordered={true}
                scroll={{ x: "max-content" }}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
                onChange={handleTableChange}
              />
            )}
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

const App = () => (
  <ApolloProvider client={client}>
    <AdminTable />
  </ApolloProvider>
);

export default App;
