export const items = [
  {
    label: "Chief Executive Officer (CEO)",
    value: "Chief_Executive_Officer_CEO",
  },
  {
    label: "Chief Technology Officer (CTO)",
    value: "Chief_Technology_Officer_CTO",
  },
  {
    label: "Chief Operating Officer (COO)",
    value: "Chief_Operating_Officer_COO",
  },
  {
    label: "Chief Information Officer (CIO)",
    value: "Chief_Information_Officer_CIO",
  },
  {
    label: "Chief Financial Officer (CFO)",
    value: "Chief_Financial_Officer_CFO",
  },
  {
    label:
      "Chief Security Officer (CSO) or Chief Information Security Officer (CISO)",
    value:
      "Chief_Security_Officer_CSO_or_Chief_Information_Security_Officer_CISO",
  },
  { label: "Chief Data Officer (CDO)", value: "Chief_Data_Officer_CDO" },
  {
    label: "Chief Human Resources Officer (CHRO)",
    value: "Chief_Human_Resources_Officer_CHRO",
  },
  {
    label: "Chief Product Officer (CPO)",
    value: "Chief_Product_Officer_CPO",
  },
  {
    label: "Chief Development Officer (CDO)",
    value: "Chief_Development_Officer_CDO",
  },
  {
    label: "Chief Strategy Officer (CSO)",
    value: "Chief_Strategy_Officer_CSO",
  },
  { label: "Others", value: "Others" },
];


export const industry = [
  {
    label: "Software Services",
    value: "Software_Services",
  },
  {
    label: "IoT",
    value: "IoT",
  },
  {
    label: "IIoT",
    value: "IIoT",
  },
  {
    label: "AI/ML",
    value: "AI/ML",
  },
  {
    label: "Cloud Computing",
    value: "Cloud Computing",
  },
  {
    label: "Cybersecurity",
    value: "Cyber_security",
  },
  {
    label: "Data Analytics",
    value: "Data_Analytics",
  },
  {
    label: "EdTech",
    value: "Ed_Tech",
  },
  {
    label: "FinTech",
    value: "FinTech",
  },
  {
    label: "HealthTech",
    value: "Health_Tech",
  },

  { label: "Others", value: "Others" },
];